import { RemixBrowser, useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { startTransition, StrictMode, useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

import { I18nextProvider, initI18nClient } from '-/i18n';
import en from '~/locales/en';
import { initNewRelicBrowser } from 'newrelicBrowser';

if (window?.appConfig?.newRelicLicenseKey) {
  initNewRelicBrowser();
}

if (window?.appConfig?.sentryDsn) {
  Sentry.init({
    dsn: window.appConfig.sentryDsn as string,
    tracesSampleRate: 1,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.remixRouterInstrumentation(
          useEffect,
          useLocation,
          useMatches
        ),
      }),
      new Sentry.Replay(),
    ],
    environment: window.appConfig.remixApp,
  });
}

async function hydrate() {
  const i18next = await initI18nClient({
    supportedLngs: ['en'],
    resources: { en: { translation: en } },
  });

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </I18nextProvider>
    );
  });
}

if (typeof requestIdleCallback === 'function') {
  requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1);
}
