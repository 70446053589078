export default {
  forgotPassword: {
    title: 'Password recovery',
    instructions:
      'Enter the email that you used when creating your account and we will send you everything you need to recover your password.',
    titleSent: 'Password recovery sent',
    instructionsSent:
      'If {{email}} matches with an account in our system, an email will be sent with details on how to reset your password.',
    genericError:
      'Oops! Something went wrong. Please try again later or contact us',
    goToSignInFromPasswordRecovery: "Don't need help anymore?",
    emailField: 'Email',
    signInLink: 'Sign in here!',
    recoverCta: 'Recover password',
  },
  recoverPassword: {
    title: 'Reset password',
    cta: 'Recover password',
    goToForgotPasswordFromRecover: 'Link expired?',
    forgotPasswordNewLink: 'Get a new one here.',
    passwordChangedSuccessfully: 'Password reset successfully!',
    passwordField: 'Password',
    passwordConfirmationField: 'Confirm Password',
    resetCta: 'Reset',
  },
  signin: {
    title: 'Welcome back to The Leap!',
    cta: "Let's go",
    ctaTransition: 'Logging in...',
    genericError: "Oops! We couldn't find an account with that combo.",
    signUpLink: 'Sign up!',
    goToSignUp: 'Need an account?',
    goToForgotPassword: 'New password?',
    forgotPasswordLink: 'Help is here',
    orContinueWith: 'Or',
    googleSignIn: 'Sign in with Google',
    thinkificSignIn: 'Sign in with Thinkific',
    emailField: 'Email',
    passwordField: 'Password',
    signinAnotherWay: 'Sign in a different way',
  },
  signup: {
    title: 'Claim your free creator store!',
    cta: 'Sign me up',
    ctaTransition: 'Creating account...',
    with: 'Sign up with',
    handleField: {
      label: 'Username',
      validation: {
        handleLengthInvalid: 'Handle must be between 2 and 30 characters.',
        handleInvalidCharacters:
          "Handle can include letters, numbers, and only '.' or '_'",
      },
    },
    emailField: 'Email',
    passwordField: 'Password',
    orContinueWith: 'Or',
    googleSignUp: 'Sign up with Google',
    thinkificSignUp: 'Sign up with Thinkific',
    agreement: 'By signing up, you agree to our',
    goToSignIn: 'Already have an account?',
    signInLink: 'Sign in here!',
    marketingOptIn:
      'Yes, I want to receive helpful resources and updates from The Leap',
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    and: 'and',
    continueWithEmail: 'Sign up with email',
    signupAnotherWay: 'Sign up a different way',
  },
  successfullyConfirmedEmail: 'You have successfully confirmed your email.',
  verify: {
    title: "Let's verify your account!",
    subtitle:
      'To keep your account secure, please enter the verification code sent to your email.',
    code: 'Verification code',
    verifyButton: 'Submit code',
    verifying: 'Verifying...',
    resendMessage:
      "Didn't receive code? It may take up to two minutes to arrive.",
    resendVerificationButton: 'Resend verification code',
    goBackTo: 'Go back to',
    needNewAccount: 'Need a new account?',
    signIn: 'Sign in',
    signUp: 'Sign up',
    resentCodeMessage:
      'A new code has been sent to your email. Check your inbox 📧',
  },
  thinkific: {
    siteUrlField: 'Enter your Thinkific site URL to continue',
    hint: 'Your site URL can be found on the settings page in the Thinkific admin',
    btnText: 'Continue to Thinkific sign in'
  },
};
