import accountsLocales from '~/accounts/locales/en';
import creatorAdminLocales from '~/creatorAdmin/locales/en';
import storefrontLocales from '~/storefront/locales/en';
import errorLocales from './common/errors';

export default {
  ...creatorAdminLocales,
  ...accountsLocales,
  ...storefrontLocales,
  ...errorLocales,
};
