export default {
  error: {
    noDescription:
      'Oops! We need a description to help you get started. Please add a brief description and we will generate the landing page.',
    unsafeDescription:
      "Oops! We can't generate a landing page for what you described. Please try a different description for your product.",
    errorCreatingProduct: 'Oops! Something went wrong. Please try again later.',
    noNiche:
      'Oops! We need a topic to help you get started. Please add a topic before generating learning outcomes.',
    unsafeNiche:
      "Oops! We can't generate learning outcomes for that topic. Please try a different topic.",
    paymentsCannotBeUpdated:
      'Payment settings could not be updated, please try again',
    layout: {
      title: 'Oops!',
      goBackToHome: 'Go back to home',
    },
    server: {
      line1: "Sorry to interrupt your flow, we're experiencing some hiccups 🙃",
      line2:
        "We're working on a fix. In the meantime, we'll cool it on giving our computers soda.",
      line3: 'Please try again later, or you can',
      contactUs: 'contact us',
      upgradeRequired:
        'This feature is not available for your current plan. Please upgrade to access this feature.',
      unauthorized: "Sorry, you don't have permission to complete this action.",
    },
    client: {
      default: {
        line1:
          'Something unexpected happened while processing your request. We apologize for the inconvenience 🙁',
        line2:
          "Please double-check your request and try again or visit the Leap's homepage.",
      },
      400: {
        line1: 'Oops! Your request seems to be missing or has invalid data 🧐',
        line2: 'Please double-check your request and try again.',
      },
      401: {
        line1: "Sorry, you can't access this resource 🔐",
        line2:
          "Please make sure you're logged in with the appropriate credentials.",
      },
      403: {
        line1: "You don't have permission to view this content 🚫",
        line2: "Please double check the URL or visit the Leap's homepage.",
      },
      404: {
        line1: "Looks like we didn't stick the landing on this leap! 🤸‍♀️",
        line2: "We can't find the page you're looking for.",
        line3: "Please double check the URL or visit the Leap's homepage.",
      },
    },
    generic:
      "Oops! Something went wrong that we didn't anticipate. Please try again in a bit, or reach out to our support at hello@theleap.co.",
    undefined: 'Oops! Something went wrong. Please try again later.',
  },
  invalidConfirmEmailToken: 'Invalid confirmation token. Please try again',
  passwordConfirmationInvalid: 'Passwords do not match',
};
