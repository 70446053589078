export const localeEn = {
  leapPages: {
    callout: {
      badge: {
        oneTimePayment: 'Buy for {{amount}}',
        emailCapture: 'Get it free',
        free: 'Get it free',
        payWhatYouWant: 'Pay what you want',
        open: 'Open link',
      },
      emailCapture: {
        signup: 'Sign up now',
        learnMore: 'Learn more',
      },
    },
    navigation: {
      nextSlide: 'Go to next option',
      prevSlide: 'Go to prev option',
    },
    presetPickerOption: 'The {{preset}} preset: {{descriptor}}',
    previews: {
      storefront: 'Store',
      landingPage: 'Landing page',
    },
    previewStore: 'Preview design',
    fulfillment: {
      footerTitle: {
        ONE_TIME_PAYMENT: {
          cta: 'Buy for {{price}}',
        },
        EMAIL_CAPTURE: {
          cta: 'Get it free',
          captionWaitlist: 'Enter your email address',
          caption: 'Enter your email to get this for free',
          name: 'Your name',
        },
        PAY_WHAT_YOU_WANT: {
          cta: 'Pay what you want',
          caption: 'Enter the amount you want to pay',
        },
      },
      askMeAnythingQuestion: {
        label: 'Enter your question for {{handle}} (max 1,000 characters)',
      },
      paymentCta: 'Proceed to checkout',
      emailCaptureCta: 'Sign me up',
      agreements: {
        claim: 'By signing up, you agree to our',
        privacyPolicy: 'privacy policy',
        termsOfService: 'terms of service',
        and: 'and',
      },
      purchaseNotAvailable:
        'The product is currently unavailable and can not be purchased.',
      emailCaptureNotAvailable:
        'The email capture is currently unavailable for sign up.',
      marketingOptIn:
        'I consent to receiving future updates and offers from {{handle}} and The Leap.',
    },
    poweredByFooter: {
      poweredBy: 'Powered by',
      theLeap: 'The Leap',
      plgFooter: 'Create your own store with',
      outreachFooter: 'Join and claim this store with',
    },
    label: {
      header: 'Header',
      fontFamilySerif: 'Font Family Serif',
      fontFamilySans: 'Font Family Sans',
      background: 'Background',
      foreground: 'Foreground',
      radius: 'Radius',
      block: 'Block',
      blockForeground: 'Block Foreground',
      blockShadow: 'Block Shadow',
      badge: 'Badge',
      badgeForeground: 'Badge Foreground',
      badgeRing: 'Badge Ring',
      badgeRadius: 'Badge Radius',
    },
    theme: {
      title: 'Theme',
      layout: {
        title: 'Layout',
        types: {
          stack: 'Stack',
          portrait: 'Portrait',
          banner: 'Banner',
          compact: 'Compact',
        },
      },
      color: 'Color',
      preset: 'Preset',
    },
    emptyState: {
      title: 'Your storefront is looking little empty 👻',
      description:
        'Once you have created a product and set the status to live, it will appear here.',
    },
    error: {
      server: {
        line1:
          "Sorry to interrupt your flow, we're experiencing some hiccups 🙃",
        line2:
          "We're working on a fix. In the meantime, we'll cool it on giving our computers soda.",
        line3: 'Please try again later, or you can',
        contactUs: 'contact us',
      },
      client: {
        default: {
          line1:
            'Something unexpected happened while processing your request. We apologize for the inconvenience 🙁',
          line2: 'Please double-check your request and try again.',
        },
        400: {
          line1:
            'Oops! Your request seems to be missing or has invalid data 🧐',
          line2: 'Please double-check your request and try again.',
        },
        401: {
          line1: "Sorry, you can't access this resource 🔐",
          line2:
            "Please make sure you're logged in with the appropriate credentials.",
        },
        403: {
          line1: "You don't have permission to view this content 🚫",
          line2: 'Please double check the URL.',
        },
        404: {
          line1: "Looks like we didn't stick the landing on this leap! 🤸‍♀️",
          line2: "We can't find the page you're looking for.",
          line3: 'Please double check the URL.',
        },
      },
    },
  },
};
